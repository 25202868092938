import type { GetStaticProps, NextPage } from 'next';
import { getGalleryTags } from 'day8-react/build/content';
import wp, { sanitizeDestinationResponse, sanitizeWpResponse } from '../helpers/wp';
import { getDestinations, getSocialFeed, getFestivals } from '../helpers/api';
import { BgImageType, CTAType, DestinationType, WpPageType } from '../globalTypes';
import HomepageWrapper from '../components/HomepageWrapper';
import { HowItWorksType } from './how-it-works';

export type FrontPage = {
  page: WpPageType & {
    acf: {
      flex_sticky_banner?: { text: string, link: string }
      homeheroslides: {
        title: string,
        subtitle: string,
        background: BgImageType
        video: any
        mobile_background: any
        mobile_video: any
        countdown?: string
        button: CTAType
      }[]
    }
  },
  howItWorksPage: HowItWorksType["page"]
  isAdsLandingPage?: boolean
  socialFeed: []
  destinations: DestinationType[]
  galleryTags: WpPageType[]
  mainDestinations?: DestinationType[]
  festivals?: DestinationType[]
}

const trustPilot = {
  link: '#reviews',
  score: '4-5',
  color: '#ffffff'
};

export const destinationSlugs = ['croatia', 'greece', 'sardinia', 'sardinia-refined-route', 'caribbean'];
export const destinationFestivals = ['croatia-drumcode', 'ultra', 'greece-applebum', 'greece-peche', 'rhythm-waves', 'vibras'];

const Home: NextPage<FrontPage> = props => <HomepageWrapper page={props.page} mainDestinations={props.mainDestinations} festivals={props.festivals} howItWorksPage={props.howItWorksPage} isAdsLandingPage={props.isAdsLandingPage} socialFeed={props.socialFeed} destinations={props.destinations} galleryTags={props.galleryTags} />;
export const getStaticProps: GetStaticProps = async () => {
  try {
    const isAdsLandingPage = false;
    const pages: WpPageType[] = await wp.pages().slug('frontpage').get();
    const destinations = await getDestinations();
    const mainDestinations = destinations.filter((dest: any) => destinationSlugs.includes(dest.slug));
    const cmsFestivals = await getFestivals();
    const festivals = cmsFestivals.filter((dest: any) => destinationFestivals.includes(dest.slug));
    const galleryTags: WpPageType[] = await getGalleryTags() || [];
    const howItWorksPage = await wp.pages().slug('how-it-works').get();
    return {
      props: {
        destinations: destinations.map((d: DestinationType) => sanitizeDestinationResponse(d, true)),
        isAdsLandingPage,
        mainDestinations,
        festivals,
        page: sanitizeWpResponse(pages[0]),
        galleryTags: galleryTags.map((g: WpPageType) => ({ id: g.id, name: g.name })),
        howItWorksPage: sanitizeWpResponse(howItWorksPage[0])
      },
      revalidate: 300
    };
  } catch (e) {
    return { notFound: true };
  }
};

export default Home;
